import React from 'react'

import styled from 'styled-components'

const Gradient = styled.div`
  display: block;
  position: absolute;
  margin: 0;
  width: 100SVW;
  height: 100SVH;
  top:0;
  left: 0;
  right: 0;
  z-index: -1;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.5) 35%, rgba(255,255,255,0) 100%);
`

const BackgroundVideo = styled.video`
  display: block;
  position: absolute;
  margin: 0;
  height: 100svh;
  top:0;
  left: 0;
  right: 0;
  z-index: -2;
  background-size: cover;
  background-position-x: center;

  @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
    height: 100VH;
  }
  @media (min-width: 992px) { // Large devices (desktops, 992px and up)
    width: 100lvw;
  }
  // X-Large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) { 

  }

  // XX-Large devices (larger desktops, 1400px and up)
  @media (min-width: 1400px) { 
    background-position-x: center;
  }
`

const HeroBg = ({bgVideo}) => {

  const video = bgVideo

  return (
    <>
      <Gradient></Gradient>
      <BackgroundVideo loop muted autoPlay>
          <source src={video} type='video/mp4'/>
      </BackgroundVideo>
    </>    
  )
}

export default HeroBg
