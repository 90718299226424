import React from 'react'
import PropType from "prop-types"

import styled from "styled-components"
import Btn from './button'
import * as variables from '../utilities/style-variables.js';

const ContentBox = styled.div`
    margin-bottom: 100px;
    padding: 0 20px;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        padding: 0;
    } 
`

const H3 = styled.h3 `
    position: relative;
    font-size: ${variables.font_size__mobile_small};
    font-weight: ${variables.font_bold};
    margin-bottom: 20px;
    padding: 10px 0 5px;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        font-size: ${variables.font_size__tablet_small};
    } 
    @media (min-width: 992px) { // Large devices (desktops, 992px and up)
        font-size: ${variables.font_size__desktop_small};
    }
`

const Text = styled.p `
    font-size: ${variables.font_size__mobile_text};
    padding: 10px 0 20px;
    white-space: pre-line;

    @media (min-width: 768px) { // Medium devices (tablets, 768px and up)
        padding: 20px 0;
    } 

    & {
        a {
            font-style: italic;
            font-weight: ${variables.font_bold};
            text-decoration: underline;
        }
    }
`

const ContentBlock = ({ heading, headingBgStart, headingBgWidth, text, linkTo, btnText, linkToExternal}) => {
    return (
        <ContentBox>
            <H3 headingBgStart={headingBgStart} headingBgWidth={headingBgWidth} className="left">{heading}</H3>
            <Text>{text}</Text>
            {btnText && 
              <Btn 
                linkToExternal={linkToExternal}
                text={btnText}
                href={linkTo}
              />
            }
        </ContentBox>
    )
}

ContentBlock.prototype = {
    heading: PropType.string.isRequired,
    headingBgStart: PropType.number.isRequired,
    headingBgWidth: PropType.number.isRequired,
    text: PropType.string.isRequired,
    linkTo: PropType.string.isRequired,
    btnText: PropType.string.isRequired,
}

ContentBlock.defaulType = {
    heading: "",
    headingBgStart: 0,
    headingBgWidth: 100,
    text: "",
    linkTo: "",
    btnText: "",
}

export default ContentBlock
