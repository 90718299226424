import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Section from "../components/section"
import ContentBlock from "../components/contentBlock"
import ContactForm from "../components/contactForm"
import Card from "../components/card"
import { StaticImage } from "gatsby-plugin-image"
import HeroBg from "../components/heroBg"
import bgVideo from "../images/video/background/gartenfreunde-bg-video.mp4"

const IndexPage = ({ location }) => (

  <>
  {/* Hero section  */}
  <Layout>
    <Seo 
      title="Gartenfreunde Offenburg e.V. | Hobby-Gärtner aus Leidenschaft"
      description="Hallo, wir sind die Gemeinschaft der Gartenfreunde Offenburg e.V. Hier finden Sie alle Infos und Kontaktinformationen zu unserem Verein."
      lang="de"
      pathname={location.pathname}
      robots="index,follow"
    />
    <Section heroSection>
      <div className="row">
        <div className="col-lg-8">
          <p>Willkommen bei der</p>
          <h1 className="">Gemeinschaft der Gartenfreunde Offenburg e.V.</h1>
          <p className="subheading align-left">Schön, dass Sie zu uns gefunden haben! Hier finden Sie alle Neuigkeiten zu unserer Gartengemeinschaft.</p>        
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <Card            
            heading="Aktuelle Info zur Warteliste"
            text="Die Warteliste für einen Gartens ist voll.
            Vorerst werden daher keine weiteren Anfragen entgegengenommen. Informationen zur Öffnung der Warteliste, werden zum gegebenen Zeitpunkt hier veröffentlicht."
          />          
        </div>
      </div>
    </Section>
    <HeroBg 
      bgVideo={bgVideo}
    />

    <Section>
      <div className="row" id="success">
        <div className="col">
          <div className="headings">
            <h2>Unser Verein</h2>
          </div>
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/images/vereinsheim-giesskanne.jpeg" 
            alt="Gartenfreunde Offenburg e.V. Vereinsheim - Gießkanne" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Unser Vereinsheim"
            text={<>Geselliger Gärtnertreffpunkt bei Speis und Trank - Das ist das Motto unseres Vereinsheims "Gießkanne".
            Jeweils 2 Mal / Monat bewirten unsere Gartenfreunde am Freitagabend und heißen Sie in fröhlicher Runde zu einem leckeren Vesper, einem Bierchen oder Wein herzlich Willkommen.</>}
          />
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6">
          <StaticImage 
            src="../images/images/Gartenfreunde-Voelklinger-Huette.JPG" 
            alt="Gartenfreunde Offenburg e.V. beim Jahresausflug 2018" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6 order-md-last">
          <ContentBlock 
            heading="Unsere Gemeinschaft"
            text={<>In unserem Gartenverein steht eine Sache immer an erster Stelle: Unsere Gemeinschaft!<br /><br />
              Zusammen mit unseren Mitgliedern gehen wir regelmäßig auf große und kleine Ausflüge, Grillen zusammen oder verschönern unsere Gartenanlage. Auch Ideen, Tipps und Trends zu unserer Lieblingsfreizeitbeschäftigung tauschen wir gerne in unserem Vereinsheim aus, wo unsere Multi-Kulti Hobby-Gärtner regelmäßig auf ein Vesper und Getränk vorbeischauen.</>}
          />
        </div>
      </div>
      <div className="row content-row">
        <div className="col-md-6 order-md-last">
          <StaticImage 
            src="../images/images/Unsere-Gaerten.jpeg" 
            alt="Gartenfreunde Offenburg e.V. Gartenanlage" 
            formats={["auto","webp"]}
            width={500}
            placeholder="blurred"
            layout="constrained"
          />
        </div>
        <div className="col-md-6">
          <ContentBlock 
            heading="Gemeinschaftsarbeiten"
            text={<>Die Pächter eines Gartens in unserem Verein, müssen laut Satzung zehn Stunden Gemeinschaftsarbeit für den Verein ableisten. Dokumentiert wird das ganze mit Hilfe einer Arbeitskarte, die allen Gartenpächter, am Jahresanfang, ausgehändigt wird.
              <br /><br />
              Der Vorstand der Gartenfreunde setzt zwischen April und Dezember mehrfach Arbeitstage an, an denen Gemeinschaftswege, Umzäunungen, das Vereinsheim Gebäude, Spielplatz und Parkplätze gesäubert, gepflegt oder ausgebessert werden. Bei nicht geleisteten Arbeitsstunden werden diese mit acht Euro in der Jahresrechnung berechnet.
              ‍<br /><br />
              Mit der Gemeinschaftsarbeit  entlastet der Verein die Kommune finanziell sowie arbeitstechnisch. Zusätzlich schauen die Gärtner über ihren Garten hinaus und stärken damit ihr Gemeinschaftsgefühl.</>}
          />
        </div>
      </div>
    </Section>

    <Section >
      <div className="row">
        <div className="col">
          <div className="headings">
            <h2>Nicht schüchtern sein.<br />
Einfach Kontakt aufnehmen.</h2>
            <p className="subheading">Sie haben eine Frage an uns? Interessieren sich für einen Garten? Oder wollen mehr über anstehende Veranstaltung wissen?
Dann schreiben Sie uns und wir melden uns bei Ihnen schnellstmöglich.</p>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <ContactForm />
        </div>
      </div>
    </Section>
    
  </Layout>
  </>
)
export default IndexPage
